<template>
	<div>
		<div v-show="pageShow">
			<div>
				<div class="que-detail que-common">
					<div class="title">{{ detail.title }}</div>
					<div class="row cb">
						<div class="row tags">
							<div class="row cc coin" v-if="detail.type == 1">
								<el-image :src="require('@/assets/coin.png')" class="img" fit="contain"></el-image>
								<span>{{ detail.point }}</span>
							</div>
							<div class="row cc red-packet" v-if="detail.type == 2">
								<el-image :src="require('@/assets/redpacket.png')" class="img" fit="contain"></el-image>
								<span>{{ detail.amount }}元</span>
							</div>
							<div class="row cc tag" v-for="(tVal, tKey) in detail.tags_name" :key="tKey">{{ tVal }}</div>
							<div class="row cc tag hot" v-if="false">热门</div>
						</div>
						<div class="row operate">
							<div class="row cc btn">
								<el-image :src="require('@/pc/assets/ask/1.png')" class="img" fit="contain"></el-image>
								<span>{{ detail.view_num }} 次浏览</span>
							</div>
							<div class="row cc btn">
								<el-image :src="require('@/pc/assets/ask/2.png')" class="img" fit="contain"></el-image>
								<span>{{ detail.answer_num }} 条回答</span>
							</div>
						</div>
					</div>
					<div class="content">{{ detail.content }}</div>
					<div class="row ask-img">
						<div class="img-box" v-for="(imgV, imgK) in askImages" :key="imgK" @click="previewImg(imgK)">
							<el-image class="img" :src="imgV" fit="cover" :preview-src-list="askImages"></el-image>
						</div>
					</div>
					<div class="row cc unfold a" v-if="false">
						<span>展开全部</span>
						<i class="el-icon-arrow-down" style="color: #666666;"></i>
					</div>
					<div class="row cb bottom">
						<div class="row cc left">
							<template v-if="isMyAsk">
								<div class="row cc a btn write" @click="scrollToAccept()">
									<el-image :src="require('@/pc/assets/ask/accept.png')" class="img" fit="contain"></el-image>
									<span>采纳回答</span>
								</div>
								<div class="row cc a btn edit" v-if="false">
									<el-image :src="require('@/pc/assets/ask/edit.png')" class="img" fit="contain"></el-image>
									<span>修改问题</span>
								</div>
								<div class="row cc a btn edit" @click="delAsk()">
									<i class="el-icon-delete-solid"></i>
									<span>删除问题</span>
								</div>
							</template>
							<template v-else>
								<div class="row cc a btn write" @click="scrollToEditor()">
									<el-image :src="require('@/pc/assets/ask/8.png')" class="img" fit="contain"></el-image>
									<span>去回答</span>
								</div>
								<div class="row cc a btn edit" @click="tapCollect()" v-show="detail.is_focus === '1'">
									<el-image :src="require('@/pc/assets/ask/6.png')" class="img" fit="contain"></el-image>
									<span>取关问题</span>
								</div>
								<div class="row cc a btn sub" @click="tapCollect()" v-show="detail.is_focus === '0'">
									<el-image :src="require('@/pc/assets/ask/6.png')" class="img" fit="contain"></el-image>
									<span>关注问题</span>
								</div>
							</template>
							<el-popover placement="top" trigger="click">
								<div class="col cc pop-qr-view">
									<div class="row cc wechat">
										<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
										<span>微信扫描二维码</span>
									</div>
									<el-image fit="contain" :src="pageUrlQrCode" class="qrcode-img"></el-image>
									<div class="row cc tips">访问移动端</div>
								</div>
								<template slot="reference">
									<div class="row cc operate-btn a">
										<el-image :src="require('@/pc/assets/ask/4.png')" class="img" fit="contain"></el-image>
										<span>分享</span>
									</div>
								</template>
							</el-popover>
							<el-popover placement="top" trigger="click">
								<div class="col cc pop-qr-view">
									<div class="row cc wechat">
										<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
										<span>微信扫描二维码</span>
									</div>
									<el-image fit="contain" :src="pageUrlQrCode" class="qrcode-img"></el-image>
									<div class="row cc tips">访问移动端</div>
								</div>
								<template slot="reference">
									<div class="row cc operate-btn a">
										<i class="el-icon-warning" style="font-size: 16px;color: #999999;"></i>
										<span>举报</span>
									</div>
								</template>
							</el-popover>
						</div>
						<div class="date">发布于/最后修改与：{{ detail.update_time_text }}</div>
					</div>
				</div>
				<div class="row cb part-view" id="accept">
					<div class="part-title">{{ total }}个回答</div>
					<div class="row cc status-view">
						<el-popover trigger="manual" placement="bottom" width="74" :visible-arrow="false" v-model="visibleStatus">
							<div class="status-content">
								<template v-for="(v, k) in status">
									<div class="row cc item a" :class="{ active: k == sortIndex }" @click="setSort(k)">{{ v }}</div>
								</template>
							</div>
							<div class="row cc status-text a" slot="reference" @click="visibleStatus = !visibleStatus">
								<div class="title">{{ sortText }}</div>
								<i class="icon el-icon-caret-bottom"></i>
							</div>
						</el-popover>
					</div>
				</div>
				<div class="col answer-list">
					<template v-for="(item, index) in list">
						<div class="col answer-item">
							<div class="row cb">
								<div class="row user a" @click="navUserHome(item.user_id)">
									<el-image :src="item.avatar" class="avatar" fit="contain"></el-image>
									<div class="nickname">{{ item.nickname }}</div>
									<div class="row auth" v-if="item.is_cert == 1">
										<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
										<div class="status">{{ item.major_name }}</div>
									</div>
									<div class="row cc lou">楼主</div>
								</div>
								<div class="row right cc">
									<div class="row cc btn disable a" v-if="item.is_accept == 1">已采纳</div>
									<div class="row cc btn accept a" v-if="isMyAsk && detail.reply_status != 3" @click.stop="acceptAnswer(index)">采纳答案</div>
								</div>
							</div>
							<div class="date">回答于 {{ item.create_time_text }}</div>
							<div class="content a" v-html="item.content" @click="openReply(index)"></div>
							<div class="row operate">
								<div class="row cc like zan a" @click="agreeAnswer(index, 1)">
									<el-image :src="require('@/pc/assets/ask/zan.png')" class="img" fit="contain"></el-image>
									<span>赞同 {{ item.agree_num }}</span>
								</div>
								<div class="row cc like cai a" @click="agreeAnswer(index, 0)">
									<el-image :src="require('@/pc/assets/ask/cai.png')" class="img" fit="contain"></el-image>
									<span>反对 {{ item.oppose_num }}</span>
								</div>
								<div class="row cc btn a" @click="openReply(index)">
									<el-image :src="require('@/pc/assets/ask/2.png')" class="img" fit="contain"></el-image>
									<span>评论 {{ item.reply_num }}</span>
								</div>
								<div class="row cc">
									<el-popover placement="top" trigger="click">
										<div class="col cc pop-qr-view">
											<div class="row cc wechat">
												<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
												<span>微信扫描二维码</span>
											</div>
											<el-image fit="contain" :src="pageUrlQrCode" class="qrcode-img"></el-image>
											<div class="row cc tips">访问移动端</div>
										</div>
										<template slot="reference">
											<div class="row cc btn a">
												<el-image :src="require('@/pc/assets/ask/4.png')" class="img" fit="contain"></el-image>
												<span>分享</span>
											</div>
										</template>
									</el-popover>
								</div>
								<div class="row cc">
									<el-popover placement="top" trigger="click">
										<div class="col cc pop-qr-view">
											<div class="row cc wechat">
												<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
												<span>微信扫描二维码</span>
											</div>
											<el-image fit="contain" :src="pageUrlQrCode" class="qrcode-img"></el-image>
											<div class="row cc tips">访问移动端</div>
										</div>
										<template slot="reference">
											<div class="row cc operate-btn a">
												<i class="el-icon-warning" style="font-size: 16px;color: #999999;"></i>
												<span>举报</span>
											</div>
										</template>
									</el-popover>
								</div>
							</div>
							<div v-if="item.replyShow">
								<el-form ref="form" style="margin-top: 26px;margin-bottom: -22px;">
									<el-form-item>
										<el-input
											type="textarea"
											autofocus
											:autosize="{ minRows: 4, maxRows: 8 }"
											maxlength="120"
											show-word-limit
											v-model="item.replyInput"
											placeholder="我来说两句……"
										></el-input>
									</el-form-item>
									<el-form-item style="text-align: right;">
										<el-button type="primary" @click="replySubmit(index)">评论</el-button>
										<el-button @click="item.replyShow = false">取消</el-button>
									</el-form-item>
								</el-form>
							</div>
							<div class="comment-view" v-if="item.comment_list.length > 0">
								<div class="comment-item" v-for="(value, key) in item.comment_list" :key="key">
									<div class="row user a" @click="navUserHome(value.user_id)">
										<el-image :src="value.avatar" class="avatar" fit="contain"></el-image>
										<div class="nickname">{{ value.nickname }}</div>
										<div class="row auth" v-if="value.is_cert == 1">
											<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
											<div class="status">{{ value.major_name }}</div>
										</div>
										<div class="row cc lou" v-if="value.is_que_user == 1">楼主</div>
										<div class="reply" @click.stop="openReply(index, key)">回复</div>
									</div>
									<div class="date">回复于 {{ value.create_time_text }}</div>
									<div class="content">
										<span v-if="value.reply_nickname">回复{{ value.reply_nickname }}：</span>
										<span v-html="value.content" @click="openReply(index, key)"></span>
									</div>
									<div v-if="value.replyShow">
										<el-form ref="form" style="margin-top: 26px;margin-bottom: -22px;">
											<el-form-item>
												<el-input
													type="textarea"
													autofocus
													:autosize="{ minRows: 4, maxRows: 8 }"
													maxlength="120"
													show-word-limit
													v-model="value.replyInput"
													:placeholder="'回复 ' + value.nickname"
												></el-input>
											</el-form-item>
											<el-form-item style="text-align: right;">
												<el-button type="primary" @click="replySubmit(index, key)">评论</el-button>
												<el-button @click="value.replyShow = false">取消</el-button>
											</el-form-item>
										</el-form>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="paginate-view">
						<div class="row cc loading-view" v-show="loading && !list.length == 0">加载中……</div>
						<div class="skeleton-view" v-show="loading && list.length == 0">
							<el-skeleton animated class="skeleton-item" />
							<el-skeleton animated class="skeleton-item" />
							<el-skeleton animated class="skeleton-item" />
						</div>
						<div v-show="!loading && list.length == 0"><el-empty description="暂无回答"></el-empty></div>
						<div class="row cc pagination" v-show="total > 0">
							<el-pagination
								background
								layout="prev,pager,next"
								:current-page="page"
								:page-size="pageSize"
								:total="total"
								@current-change="pageChange"
							></el-pagination>
						</div>
					</div>
				</div>
				<!-- 写回答 -->
				<div class="write-view" id="editor">
					<div class="title">我的回答：</div>
					<el-form ref="form" style="margin-bottom: -22px;">
						<el-form-item><Editor v-if="editorTips" v-model="answerContent" :height="200" :placeholder="editorTips"></Editor></el-form-item>
						<el-form-item style="text-align: right;">
							<el-button type="primary" round :loading="answerSubmitLoading" @click="answerSubmit()">提交回答</el-button>
						</el-form-item>
					</el-form>
				</div>
				<!-- 猜你喜欢 -->
				<div class="relate-view">
					<div class="row relate-title">猜你想问</div>
					<el-empty description="暂无猜你想问" v-if="guessList.length == 0"></el-empty>
					<div v-else class="relate-item que-common a" v-for="(gItem, gKey) in guessList" :key="gKey" @click="navAskDetail(gItem.id)">
						<div class="row cb relate-top">
							<div class="row title-box">
								<div class="title text-ellipsis">{{ gItem.title }}</div>
								<div class="row tags">
									<div class="row cc coin" v-if="gItem.type == 1">
										<el-image :src="require('@/assets/coin.png')" class="img" fit="contain"></el-image>
										<span>{{ gItem.point }}</span>
									</div>
									<div class="row cc red-packet" v-if="gItem.type == 2">
										<el-image :src="require('@/assets/redpacket.png')" class="img" fit="contain"></el-image>
										<span>{{ gItem.amount }}元</span>
									</div>
									<template v-for="(tVal, tKey) in gItem.tag_names">
										<div class="row cc tag">{{ tVal }}</div>
									</template>
									<div class="row cc tag hot" v-if="gItem.is_hot == 1">热门</div>
									<div class="row cc tag">{{ gItem.type == 1 ? '积分悬赏' : '现金悬赏' }}</div>
								</div>
							</div>
							<div class="date">{{ gItem.create_time_text }}</div>
						</div>
						<div class="row cb relate-bottom">
							<div class="row left">
								<div class="row cc status-view" v-if="gItem.reply_status == 3">
									<el-image :src="require('@/pc/assets/ask/success-item.png')" class="img" fit="contain"></el-image>
									<span>已采纳</span>
								</div>
								<div class="content text-ellipsis">{{ gItem.content }}</div>
							</div>
							<div class="row operate">
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/1.png')" class="img" fit="contain"></el-image>
									<span>{{ gItem.view_num }} 次浏览</span>
								</div>
								<div class="row cc btn">
									<el-image :src="require('@/pc/assets/ask/2.png')" class="img" fit="contain"></el-image>
									<span>{{ gItem.reply_num }} 条回答</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="!pageShow" class="skeleton-box">
			<div class="skeleton-item"><el-skeleton :rows="10" animated /></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@/pc/components/wangEditor/index.vue';
import Upload from '@/pc/components/upload/index.vue';
export default {
	data() {
		return {
			visibleStatus: false,
			status: ['最新', '热门'],
			pageShow: false,
			question_id: '',
			accountShow: false,
			reportShow: false,
			replyInput: '',
			inviteShow: false,
			list: [],
			loading: false,
			page: 1,
			pageSize: 15,
			total: 0,
			// headerHeight: '0px',
			// bottomHeight: '0px',
			listShow: false,
			sortIndex: 0,
			setting: {},
			detail: {},
			askUserInfo: {},
			currentuserInfo: {},
			answerSort: ['time', 'agree'],
			guessList: {},

			reportAnswerId: '',
			reportIndex: '',
			reportInput: '',
			reportType: [],
			inviteList: [],
			accSub: true,
			answerContent: '',
			answerSubmitLoading: false,
			editorTips: ''
		};
	},
	components: { Editor, Upload },
	computed: {
		askImages() {
			if (this.detail.image) {
				let imgs = this.detail.image.split(',');
				let result = imgs.map(e => this.$store.getters.oss(e));
				return result;
			} else {
				return [];
			}
		},
		isMyAsk() {
			return this.askUserInfo.id == this.currentuserInfo.id;
		},
		sortText() {
			return this.status[this.sortIndex] || '问题状态';
		},
		pageUrlQrCode() {
			return this.$store.getters.qrcode(this.$route.fullPath.slice(1));
		}
	},
	async created() {
		this.question_id = this.$route.query.id;
		let settingRes = this.$service.post('auth/setShow').then(res => {
			this.setting = res.data;
		});
		// 增加浏览记录
		let accessLog = this.$service.post('question/focusQue', { type: 2, id: this.question_id });
		// 问题详情
		let detailRes = this.getDetail();
		let currentuserInfo = this.$service.post('user_info/detail').then(res => {
			this.currentuserInfo = res.data;
		});
		// 猜你想问
		let guessList = this.$service.post('question/guessList', { question_id: this.question_id, limit: 2 }).then(res => {
			this.guessList = res.data;
		});
		// 是否关注公众号
		let followRes = Promise.resolve();
		if (process.env.NODE_ENV === 'production') {
			let followRes = this.$service.post('auth/is_follow').then(res => {
				this.accSub = res.data.is_follow == 1;
			});
		}
		// 输入框提示词
		let editorTips = this.$service.post('auth/answerTip').then(res => {
			this.editorTips = res.data.introduce;
		});
		// 回答列表
		let answerRes = this.loadData();

		Promise.all([settingRes, accessLog, detailRes, currentuserInfo, guessList, followRes, editorTips, answerRes])
			.then(() => {
				return this.$service.post('user_info/detail', { user_id: this.detail.user_id }).then(res => {
					this.askUserInfo = res.data;
				});
			})
			.then(() => {
				this.pageShow = true;
				this.$nextTick(() => {
					if (this.detail.dely_notice == 1) {
						this.$confirm('24小时后仍未采纳，悬赏的80%将平分给回答者', '延长悬赏24小时？', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
							.then(async () => {
								await this.$service.post('question/delayQue', { question_id: this.question_id });
								this.detail.is_focus = '0';
								this.$message({
									message: '已延长悬赏',
									type: 'success'
								});
							})
							.catch(() => {});
					} else if (this.detail.endQue_notice == 1) {
						this.$alert('悬赏的80%将平分给所有回答者', '逾期未采纳，已自动结题', {
							confirmButtonText: '知道了',
							type: 'warning'
						})
							.then(() => {})
							.catch(() => {});
					} else if (this.detail.drafts_notice == 1) {
						this.$alert('已回收至草稿箱，可编辑后重新发布', '问题暂无人回答', {
							confirmButtonText: '知道了',
							type: 'warning'
						})
							.then(() => {})
							.catch(() => {});
					}
				});
			});
	},
	methods: {
		async getDetail() {
			// 问题详情
			return this.$service.post('question/detail', { id: this.question_id }).then(res => {
				this.detail = res.data;
			});
		},
		async answerSubmit() {
			if (this.answerContent == '') {
				this.$message({
					message: '请填写回答正文内容',
					type: 'error'
				});
			} else {
				this.answerSubmitLoading = true;
				try {
					await this.$service.post('reply/answer', { question_id: this.question_id, content: this.answerContent });
					this.$message({
						message: '回答成功',
						type: 'success'
					});
					this.answerContent = '';
					setTimeout(() => {
						window.location.reload();
					}, 500);
				} catch {}

				this.answerSubmitLoading = false;
			}
		},
		async agreeAnswer(index, is_agree) {
			let item = this.list[index];
			let res = await this.$service.post('reply/isAgree', { answer_id: item.id, is_agree });
			this.$message({
				message: is_agree == 1 ? '已赞同' : '已反对',
				type: 'success'
			});
			this.list[index].agree_num = res.data.agree_num;
			this.list[index].oppose_num = res.data.oppose_num;
		},
		openReply(index, key = '') {
			let item = this.list[index];
			if (key !== '') {
				let value = this.list[index].comment_list[key];
				this.$set(value, 'replyInput', '');
				this.$set(value, 'replyInfo', {
					id: item.id,
					nickname: item.nickname,
					reply_id: value.id,
					username: value.nickname
				});
				this.$set(value, 'replyShow', true);
				this.$nextTick(() => {
					document.documentElement.scrollTop = document.getElementsByClassName('comment-view')[index].getElementsByClassName('comment-item')[key].offsetTop - 84 - 4;
				});
			} else {
				this.$set(item, 'replyInput', '');
				this.$set(item, 'replyInfo', {
					id: item.id,
					nickname: item.nickname,
					reply_id: '',
					username: ''
				});
				this.$set(item, 'replyShow', true);
				this.$nextTick(() => {
					document.documentElement.scrollTop = document.getElementsByClassName('answer-item')[index].offsetTop - 84;
				});
			}
		},
		// 确认评论回答
		async replySubmit(index, key = '') {
			if (key !== '') {
				var replyInput = this.list[index].comment_list[key].replyInput;
				var replyInfo = this.list[index].comment_list[key].replyInfo;
			} else {
				var replyInput = this.list[index].replyInput;
				var replyInfo = this.list[index].replyInfo;
			}

			if (replyInput.trim().length < 5) {
				this.$message({
					message: '评论详情不得少于五个字',
					type: 'error'
				});
			} else {
				try {
					await this.$service.post('reply/replyAnswer', {
						content: replyInput.trim(),
						answer_id: replyInfo.id,
						reply_id: replyInfo.reply_id
					});
					this.$message({
						message: '评论成功',
						type: 'success'
					});
					this.onRefresh();
				} catch {}
				if (key !== '') {
					this.list[index].comment_list[key].replyShow = false;
				} else {
					this.list[index].replyShow = false;
				}
			}
		},
		pageChange(e) {
			this.list = [];
			this.page = e;
			this.loadData();
		},
		async loadData() {
			console.log('this.loading', this.loading);
			if (!this.loading) {
				window.scroll(0, 0);
				this.loading = true;
				return this.$service
					.post('reply/answerList', {
						id: this.question_id,
						sort: this.answerSort[this.sortIndex],
						page: this.page,
						pageSize: this.pageSize
					})
					.then(res => {
						this.total = res.data.total;
						this.list = res.data.data;
						this.loading = false;
					});
			} else {
				return Promise.resolve();
			}
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 1;
			this.list = [];
			this.loadData();
		},
		setSort(index) {
			this.sortIndex = index;
			this.visibleStatus = false;
			this.onRefresh();
		},
		navAskDetail(id) {
			this.$router.push({
				path: '/ask/detail',
				query: { id }
			});
		},
		scrollToAccept() {
			document.documentElement.scrollTop = document.getElementById('accept').offsetTop - 84;
		},
		scrollToEditor() {
			document.documentElement.scrollTop = document.getElementById('editor').offsetTop - 84;
		},
		async tapCollect() {
			if (this.detail.is_focus === '1') {
				this.$confirm('你可能会错失许多精彩内容', '确定取消关注？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
					.then(async () => {
						await this.$service.post('question/delFocusQue', { type: 1, id: this.question_id });
						this.detail.is_focus = '0';
						this.$message({
							message: '已取关问题',
							type: 'success'
						});
					})
					.catch(() => {});
			} else {
				await this.$service.post('question/focusQue', { type: 1, id: this.question_id });
				this.detail.is_focus = '1';
				this.$message({
					message: '已关注问题',
					type: 'success'
				});
			}
		},
		navUserHome(user_id = '') {
			if (user_id) {
				this.$router.push({
					path: '/user/home',
					query: {
						user_id
					}
				});
			} else {
				this.$router.push({
					path: '/user/home'
				});
			}
		},
		acceptAnswer(index) {
			let item = this.list[index];
			let rate = this.detail.type == 1 ? '悬赏积分的100%' : '悬赏金额的80%';
			this.$confirm(`“${item.nickname}”将获得${rate}`, '确定采纳该答案？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					await this.$service.post('question/acceptAnswer', { answer_id: item.id });
					await this.getDetail();
					this.$message({
						message: '采纳成功',
						type: 'success'
					});
					this.onRefresh();
				})
				.catch(() => {});
		},
		delAsk() {
			this.$confirm(`您确认要删除问题`, '提示？', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					await this.$service.post('question/delete', { question_id: this.question_id });
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					setTimeout(() => {
						this.$router.replace({
							path: '/user/home'
						});
					}, 2000);
				})
				.catch(() => {});
		}
	}
};
</script>

<style scoped lang="less">
@import './detail.less';
</style>
